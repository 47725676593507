import { useMemo } from 'react';
import {
  ControlProps,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  InputProps,
  ContainerProps,
  ValueContainerProps,
  OptionProps,
  SingleValueProps,
  GroupHeadingProps,
  components,
  MenuListProps,
  MultiValueProps,
  MultiValueRemoveProps,
} from 'react-select';
import Error from '../error/Error';
import { Option } from '../../Option';
import DropDownIcon from '../icon/composite/DropDownIcon';
import XIcon from '../icon/XIcon';
import Loader from '../Loader';
import Tooltip from '../Tooltip';
import { DropdownSize, useDropdownSelect } from './DropdownSelect';
import { dataAttributeProps, mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import InfoIcon from '../icon/InfoIcon';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonType } from './Button';
import StringUtils from '../../../utils/StringUtils';

type DefaultItemValues = string | number | string[] | number[] | boolean;
export type Item<TValue = DefaultItemValues> = Option<string, TValue> & { __isNew__?: boolean };

export const BaseControlRenderer = <TItem extends Item>(props: ControlProps<TItem, boolean>) => {
  const { children, innerRef, innerProps, menuIsOpen, isDisabled } = props;
  const { label, errorState, error, className, size, required, helpText } = useDropdownSelect();
  const hasLabelClasses = useMemo(() => (label ? 'mt-9' : ''), [label]);

  return (
    <div data-cy="input-wrapper" data-has-label={!!label} className={`relative ${hasLabelClasses}`}>
      <div className={`absolute left-0 top-0 ${helpText ? '-mt-7' : '-mt-5'}`}>
        <div className="flex items-center gap-2">
          {!!label && (
            <label className="text-color-3 text-dpm-12 opacity-100 transition-opacity duration-150 ease-in-out" aria-label={label}>
              {required && <span className="text-semantic-3 pr-1 font-semibold">*</span>}
              {label}
            </label>
          )}
          {!!helpText && (
            <Tooltip text={helpText}>
              {(tooltip) => (
                <div {...tooltip}>
                  <InfoIcon className="h-5 w-5" />
                </div>
              )}
            </Tooltip>
          )}
        </div>
      </div>
      <div
        ref={innerRef}
        {...innerProps}
        {...dataAttributeProps(props)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={`flex w-full justify-between gap-1 border-2 ${
          errorState ? 'border-accent-2' : menuIsOpen ? 'border-accent-1' : 'border-primary-1'
        } px-3 ${size === DropdownSize.S ? 'text-dpm-14 py-1' : 'py-2'} ${
          isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer bg-white'
        } relative rounded-lg ${className} ${props.className}`}
      >
        {children}
      </div>
      <Error>{error}</Error>
    </div>
  );
};

export const DefaultIndicatorSeperator = () => null;

export const BaseChevron = <TItem extends Item>(props: DropdownIndicatorProps<TItem, boolean>) => {
  const { innerProps } = props;
  const { size, open, isFetching } = useDropdownSelect();

  return (
    <div {...innerProps}>
      {!isFetching && (
        <DropDownIcon
          data-cy="dropdown-icon"
          className={`${size === DropdownSize.S ? 'h-4 w-4' : 'h-5 w-5'} text-1 ml-2 flex-shrink-0`}
          open={open}
        />
      )}
      {isFetching && <Loader size={5} centered={false} />}
    </div>
  );
};

export const DefaultClearIndicator = <TItem extends Item>(props: ClearIndicatorProps<TItem>) => {
  return (
    <div {...props.innerProps}>
      <XIcon className="h-4 w-4 flex-shrink-0" />
    </div>
  );
};

export const DefaultInput = <TItem extends Item>(props: InputProps<TItem, boolean>) => {
  const { t } = useTranslation('common');
  return (
    <components.Input
      {...props}
      className="!m-0 !p-0 outline-none [&_input:valid]:!min-w-[150px] [&_input:valid]:!max-w-[150px] [&_input]:caret-[auto] [&_input]:transition-[min-width,max-width]"
      required
      aria-label={props.name || t('aria-label.dropdown')}
    />
  );
};

export const DefaultSelectContainer = <TItem extends Item>(props: ContainerProps<TItem, boolean>) => {
  const { children, ...rest } = props;

  return <components.SelectContainer {...rest}>{children}</components.SelectContainer>;
};

export const DefaultValueContainer = <TItem extends Item>(props: ValueContainerProps<TItem, boolean>) => {
  const { children, innerProps } = props;
  return (
    <div {...innerProps} className="flex items-center overflow-hidden">
      {children}
    </div>
  );
};

export const BaseOptionRenderer = <TItem extends Item>(props: Partial<OptionProps<TItem>>) => {
  const { innerRef, innerProps, data, isDisabled, label } = props;
  const { ItemRenderer, dataTags } = useDropdownSelect();

  if (!data) return null;

  return (
    <div
      ref={innerRef}
      {...(innerProps || {})}
      {...dataTags}
      data-cy="select-option"
      className={`cursor-pointer select-none py-1 pl-3 pr-2 ${
        isDisabled || data.disabled ? 'pointer-events-none cursor-default opacity-50' : 'cursor-pointer'
      } text-primary-1 hover:bg-primary-2 hover:bg-opacity-20 ${
        data.hasDivider ? 'after:border-gray-2 relative mb-2 after:absolute after:-bottom-1 after:left-0 after:w-full after:border-b' : ''
      }`}
    >
      <ItemRenderer {...data} text={label} />
    </div>
  );
};

export const BasePlaceholderRenderer = <TItem extends Item>(props: SingleValueProps<TItem, boolean>) => {
  const { innerProps, data } = props;
  const {
    ItemRenderer,
    placeholder,
    hasFocus,
    props: { customSingleValueRenderer },
  } = useDropdownSelect();
  const Item = customSingleValueRenderer || ItemRenderer;

  return (
    <div className="truncate text-black" {...innerProps}>
      {data?.id === undefined ? !hasFocus && <div className="text-gray-2 min-w-0 max-w-full truncate">{placeholder}</div> : <Item {...data} />}
    </div>
  );
};

export const DefaultOptionRenderer = <TItem extends Item>(props: TItem) => {
  const { text } = props;
  const {
    props: { highlightSearchMatch },
    inputValue,
  } = useDropdownSelect();

  let renderedText: (string | JSX.Element)[] = [text];

  if (highlightSearchMatch !== false && inputValue) {
    renderedText = StringUtils.highlightText(text, inputValue);
  }

  return (
    <Tooltip text={props.text} truncatedTextMode>
      {(tooltip) => (
        <div className="min-w-0 max-w-full truncate" {...tooltip}>
          {renderedText}
        </div>
      )}
    </Tooltip>
  );
};

export const DefaultHeadingRenderer = <TItem extends Item>(props: GroupHeadingProps<TItem, boolean>) => {
  const { children } = props;
  return <div className="border-gray-2 border-b px-2 font-medium">{children}</div>;
};

export const MenuListRenderer = <TItem extends Item>(props: MenuListProps<TItem, boolean>) => {
  const { menuPosition, dropdownListWrapper, onTertiaryButtonClick, tertiaryButtonTitle, tertiaryButtonIcon } = useDropdownSelect();
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className={`border-primary-1 relative flex w-full min-w-fit flex-col justify-between overflow-y-auto rounded-[4px] border-2 ${
        menuPosition === 'right' ? 'float-right' : ''
      } bg-white`}
      data-cy="select-options"
      style={{ maxHeight: props.maxHeight, zIndex: 800, position: 'relative' }}
    >
      {dropdownListWrapper ? dropdownListWrapper({ children: props.children }) : props.children}
      {onTertiaryButtonClick && tertiaryButtonTitle && (
        <div className="border-gray-6 sticky bottom-0 w-full cursor-pointer border-t bg-white px-4 py-2">
          <Button
            type={ButtonType.TERTIARY}
            size={ButtonSize.S}
            onClick={() => {
              onTertiaryButtonClick();
            }}
          >
            {tertiaryButtonIcon && <Button.Slot name="Icon">{tertiaryButtonIcon}</Button.Slot>}
            {tertiaryButtonTitle}
          </Button>
        </div>
      )}
    </div>
  );
};

export const SelectContainerRenderer = <TItem extends Item>(props: ContainerProps<TItem, boolean>) => {
  const { wrapperClassName, props: mainProps } = useDropdownSelect();
  return <DefaultSelectContainer {...props} className={wrapperClassName + ' ' + mainProps.className} />;
};

export const NoOptionMessage = () => {
  const { t } = useTranslation('common');
  const {
    props: { noOptionsText },
  } = useDropdownSelect();

  return <div className="text-gray-2 p-2 text-center">{noOptionsText ?? t('dropdown-list.no-options')}</div>;
};

export const MultiValueRemoveRenderer = <TItem extends Item>(componentProps: MultiValueRemoveProps<TItem>) => {
  const { props: mainProps } = useDropdownSelect();
  return (
    <div
      {...componentProps.innerProps}
      className="hover:bg-semantic-2 flex h-full cursor-pointer items-center justify-center rounded-br-lg rounded-tr-lg px-1 transition-colors hover:text-white"
      {...mouseAndKeyboardCallbackProps('onRemove' in mainProps && mainProps.onRemove ? () => mainProps.onRemove(componentProps.data) : undefined)}
    >
      <XIcon className="h-4 w-4" />
    </div>
  );
};

export const MultiValueRenderer = <TItem extends Item>(props: MultiValueProps<TItem>) => {
  const { text } = props.data;
  return (
    <components.MultiValue {...props} className="flex cursor-default items-center gap-1 !rounded-lg">
      <span>{text}</span>
    </components.MultiValue>
  );
};
